@use './variables';

// Create color divs for all of app's colors
@each $colorName, $color in $app-colors {
  .text-#{$colorName} {
    color: $color;
  }
  .bg-#{$colorName} {
    background-color: $color;
  }
  .statistic-#{$colorName} {
    .ant-statistic-content {
      color: $color;
    }
  }
  span.outline-#{$colorName} svg path {
    // For styling filled Ant design icons, for usage
    // see `DateSchedulerHelpers.js`
    stroke: $color;
    stroke-width: 65px; // I don't know why it has to be so damn thicc
  }
  .button-#{$colorName} {
    color: #fff;
    background-color: $color;

    &:hover {
      color: #fff;
      background-color: adjust-color(
        $color: $color,
        $lightness: 10%
      ) !important;
    }
    &:active {
      color: #fff;
      background-color: adjust-color(
        $color: $color,
        $lightness: -10%
      ) !important;
    }
  }
}
