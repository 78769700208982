@use './mixins';

h1 {
  @include headerFont();
}

h2 {
  @include headerFont();
}

h3 {
  @include headerFont();
}
