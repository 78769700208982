@use "./mixins";
@use "./variables";

// Rows + Columns for spacing

.app-row-w100 {
  width: 100%;
}

.app-row-center-content {
  display: flex;
  justify-content: center;
}

.leaflet-popup-content-wrapper {
  background-color: #141414;
  border-radius: 0.5rem;
  color: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 1px 5px #f5f5f5;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
  padding: 0rem;
  margin: 0rem;
}

.leaflet-popup-tip-container {
  display: none;
}

// leaflet popup
.app-popup {
  @include headerFont();
  font-size: 1rem;
}

.app.card.title {
  margin: 1rem;
}

.app-card-detail-card {
  width: 15rem;
}
.app-card-detail-card-header {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35rem;
  border-radius: 0%;
}

.app-icon-base {
  font-size: 1.5rem;
  color: aqua;
}

.app-unit-base {
  font-size: 0.9rem;
  color: aqua;
}
//OLD STUFF

// Custom card styling for buttons & extra fun stuff in the `title`
.app-card {
  margin: 2rem;
}

.app-city-card {
  margin: 2rem;
  width: 240px;
  height: 100;
}
.app-city-page {
  width: 100%;
  height: 100%;
  background-color: #133252;
}

.app-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .app-card-header-title-container {
    display: inline-block;

    .app-card-header-title {
      display: inline-block;
      @include headerFont();
    }
  }

  .app-card-header-badge {
    margin-left: 0.5rem;
  }

  .app-card-header-button-group {
    display: inline-block;
    margin-right: 0.5rem;

    .app-card-header-button {
      margin-left: 0.5rem;
      float: right;
    }
  }
}

// More general card header styling for less complex card headers
.app-card-header-title {
  display: inline-block;
  @include headerFont();
}

.app-pagination {
  margin-top: 1rem;
  margin-right: 2rem;
}

.app-error-message {
  margin: 0.5rem 0.5rem 1.25rem 0.5rem;
}

.app-page-outer {
  padding: 1rem 2rem 2rem 2rem;
  background-color: white;
}

.float-right {
  float: right;
}

.app-sider {
  background-color: white !important;
}

.app-sider-vertical-space {
  width: 100%;
  padding: 0.5rem;
}

.app-form-item-w100 {
  width: 100%;
}

.app-form-item-w500px {
  width: 500px;
}

.app-header-welcome-user {
  margin-left: auto;
}

.app-header-logo {
  padding-top: 0.5rem;
  // height: 2rem;
  width: 35px;
}

.app-header-env {
  // position: absolute;
  // left: -15px;
  // top: 10px;
  // padding-bottom: 0;
  // padding-top: 1rem;
}

.app-header-menu {
  box-shadow: 0px 1px 5px #f5f5f5;
  // padding-top: 0.5rem !important;
}

.app-header-small-margin {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

.app-home-title-row {
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.app-spin {
  margin: 1rem;
}

.ant-menu-item {
  padding-top: 0.5rem;
}

//ant-menu-overflow-item ant-menu-item ant-menu-item-only-child
//ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-dark app-header-menu

.app-home-card-icon {
  font-size: 100px;
}

.app-home-card {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.app-home-card-meta {
  text-align: center;
}

.app-imageqa-card-image {
  width: 325px;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-item-description {
  padding-left: 1rem;
}

.app-description-title {
  padding-top: 0.5rem;
}

.app-text-align-center {
  text-align: center;
}

.app-map-outer {
  margin-right: 2em;
}

.app-top-margin {
  margin-top: 1rem;
}

.app-top-margin-small {
  margin-top: 0.5rem;
}

.app-bottom-margin {
  margin-bottom: 1rem;
}

.app-bottom-margin-small {
  margin-bottom: 0.5rem;
}

.app-bottom-margin-large {
  margin-bottom: 2rem;
}

.app-no-bottom-margin {
  margin-bottom: 0 !important;
}

.app-breadcrumb-inline {
  display: inline-block;
}

.app-breadcrumb-buttonholder {
  display: inline;
  float: right;
}

.app-dummy-link {
  color: $app-blue;
  cursor: pointer;
  margin-left: 1rem;
}

.app-menu-badge {
  margin-right: 8px; // Since badges are translated & absolutely positioned, we need to add some margin

  sup {
    inset-inline-end: auto !important;
    transform: translate(
      30%,
      -50%
    ) !important; // Moves it slightly closer to inner text
    font-size: 11px !important;
  }
}

.app-blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.app-tasking-request-title {
  @include headerFont();
  font-size: 1rem;
  padding-bottom: 0.25rem;
}

.app-tasking-request-status-tag {
  margin-left: 1rem;
}

.app-tasking-request-header-row {
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

.app-header-statistic {
  line-height: 1.25;

  .ant-statistic-title {
    margin-bottom: 0.1rem;
    font-size: 12px;
  }
  .ant-statistic-content {
    padding-left: 0.25rem;
    font-size: 14px;
  }
}

.app-statistic-title {
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0.1rem;
  font-size: 12px;
}

.app-small-card {
  .ant-card-head {
    @include headerFont();
    font-size: 16px;
  }
}

.app-card-no-padding {
  .ant-card-body {
    padding: 0;
  }
}

.app-card-small-padding {
  .ant-card-body {
    padding: 1rem;
  }
}
