$background-color: white;
$app-blue: #3b26f9;
$app-black: #000;
$app-red: #ff4848;
$app-light-blue: #00e1ff;
$app-aqua: #53f5ec;
$app-green: #00aa51;
$app-pink: #f4037b;
$app-light-grey: #ebece6;
$app-grey: #c4c4c4;
$app-dusk-blue: #7395af;
$app-dark-blue: #322c56;
$app-warning: #f48403;

$app-colors: (
  app-blue: $app-blue,
  app-black: $app-black,
  app-red: $app-red,
  app-light-blue: $app-light-blue,
  app-aqua: $app-aqua,
  app-green: $app-green,
  app-pink: $app-pink,
  app-light-grey: $app-light-grey,
  app-grey: $app-grey,
  app-dusk-blue: $app-dusk-blue,
  app-dark-blue: $app-dark-blue,
  app-warning: $app-warning,
);
